<template>
  <div class="teitter-game-container layout-border" @click="handleClickScreen">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <SceneSwitchThumbnails
      :buttons="buttonList"
      :numBers="numBers"
      @changeTheNumbers="changeTheNumbers"
    >
    </SceneSwitchThumbnails>
    <div class="game-content">
      <!-- <Star :totalStarNumber="starNum" :currentIndex="curretnStarIndex" /> -->
      <div class="back-ground-img-area">
        <img src="@/assets/img/03-Backgrounds/background-practice.svg" alt="" />
      </div>
      <div class="page-title" v-if="title">
        <span class="font-text pinyin txt-py font-pinyin-medium">{{
          title.pinyin
        }}</span>
        <span class="txt-hz font-hanzi-medium">{{ title.hanzi }}</span>
      </div>
      <div class="twitter-area">
        <div class="twitter-img-area">
          <template v-for="(item, index) in gameList">
            <div class="img-item" :key="index" v-if="index === currentIndex">
              <transition name="el-fade-in">
                <img
                  v-if="currentStepIndex === 0"
                  :src="item.originImg"
                  alt=""
                />
                <img
                  v-if="currentStepIndex === 1"
                  :src="item.clickImg"
                  alt=""
                />
                <img
                  v-if="currentStepIndex === 2"
                  :src="item.wordsImg"
                  alt=""
                />
                <img
                  v-if="currentStepIndex === 3"
                  :src="item.heartImg"
                  alt=""
                />
              </transition>
            </div>
          </template>
        </div>
      </div>
      <div class="click-area">
        <!-- <div class="screen-shape" @click="handleClickScreen"></div> -->
        <div class="heart-shape" @click="handleClickHeart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
// import Star from "../Star";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "TwitterGame",
  props: {
    // starNum: {
    //   type: Number,
    //   require: true,
    // },
    gameList: {
      type: Array,
      require: true,
      default: () => [],
    },
    buttonList:{
      type: Array,
      require: true,
      default: () => [],
    },
    title: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      currentIndex: 0,
      isLastStep: false,
      clickHeartSocketInfo: {},
      clickScreenSocketInfo: {},
      currentStepIndex: 0,
      curretnIndex: 0,
      curretnStarIndex: 0,
      numBers: 1,
      
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
    // Star,
  },
  watch: {
    clickScreenSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005101,
          data: { value },
          text: "TwitterGame点击屏幕socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    // clickHeartSocketInfo: {
    //   handler(value) {
    //     const data = {
    //       clickType: 2005102,
    //       data: { value },
    //       text: "TwitterGame点击爱心socket",
    //     };
    //     this.sendSocketInfo = { ...data };
    //     this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
    //   },
    //   deep: true,
    // },
    clickAsideSocketInfo: {
      handler(value) {
        const data = {
          clickType: 3000601,
          data: { value },
          text: "speakingPageOne页面menu按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("ClickTheScreen", () => {
      this.handleClickScreen("socket", true);
    });
    this.$bus.$on("ClickTheHeart", () => {
      this.handleClickHeart("socket", true);
    });
    this.waitAppear();
  },
  beforeDestroy() {
    this.$bus.$off("ClickTheScreen");
    this.$bus.$off("ClickTheHeart");
  },

  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    changeTheNumbers(index, isFromSocket = false) {
      console.log(index);
      this.currentIndex = index-1;
      this.currentStepIndex = 0;
      this.waitAppear();
      console.log(this.currentIndex);

      if (!isFromSocket) {
        this.clickAsideSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
      if (index < 8) {
        this.isLastStep = false;
      }
    },
    handleClickScreen(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickScreenSocketInfo = {
          value: Math.random(),
        };
      }

      if (this.currentStepIndex >= 3 && this.currentIndex < 2) {
        return;
      }
      this.currentStepIndex++;
      console.log(this.currentIndex);
      if (this.currentIndex === 2 && this.currentStepIndex === 3) {
        this.isLastStep = true;
        startConfetti();
        playCorrectSound();
      }
      playCorrectSound(true, false);

      console.log(this.currentStepIndex, `第${this.currentStepIndex}张`);
    },
    handleClickHeart(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickHeartSocketInfo = {
          value: Math.random(),
        };
      }
      if (this.currentStepIndex === 2) {
        this.currentStepIndex++;

        console.log(this.currentStepIndex);
        playCorrectSound(true, false);
        const that = this;
        setTimeout(() => {
          that.toNextPart();
        }, 2000);
      }
    },
    waitAppear() {
      if (this.currentStepIndex === 0) {
        setTimeout(() => {
          this.currentStepIndex = 1;
          console.log(this.currentIndex, "1111111111");
        }, 1000);
      }
    },
    toNextPart() {
      this.currentIndex++;
      // this.curretnStarIndex++;
      this.currentStepIndex = 0;
      if (this.currentIndex >= this.gameList.length) {
        this.currentIndex = this.gameList.length - 1;
        this.isLastStep = true;
        startConfetti();
        playCorrectSound();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.teitter-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .back-ground-img-area {
      width: 100%;
      height: 100%;
      img {
        border-radius: 48px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .click-area,
    .twitter-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .twitter-area {
      display: flex;
      justify-content: center;
      align-items: center;
      .twitter-img-area {
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-item {
          width: 50%;
          height: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 20;
          img {
            cursor: pointer;
            height: 100%;
          }
        }
      }
    }
    .click-area {
      z-index: 4;
      .screen-shape,
      .heart-shape {
        position: absolute;
        // background: #000;
        border-radius: 10px;
        width: 228px;
        height: 226px;
        top: 27.5%;
        left: 37.5%;
        cursor: pointer;
      }
      .heart-shape {
        width: 30px;
        height: 20px;
        top: 68.5%;
        left: 37.5%;
      }
    }
    .page-title {
      background-color: #cd4c3f;
      border-top-right-radius: 29px;
      border-bottom-right-radius: 29px;
      position: absolute;
      top: 6%;
      left: 0;
      color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;
      flex-flow: column;
      z-index: 9;
      width: auto;
      height: auto;
      padding: 3%;
    }
  }
}
</style>